<template>
  <div class="table-library">
    <!-- main content -->
    <template v-if="getSortedLibraries.length">
      <slot name="header" />
      <div class="table-container table-container__shadowed">
        <v-data-table
          :elevation="0"
          :headers="headersForRendering"
          :items="getSortedLibraries"
          :mobile-breakpoint="0"
          data-test="library_table"
          disable-pagination
          hide-default-footer
          must-sort
          item-key="id"
          @click:row="navigateToCollectionList($event)">
          <template #[`item.name`]="{ item }">
            <div
              :id="item.id"
              data-test="library_name">
              {{ item.name }}
            </div>
          </template>
          <template #[`item.collectionCount`]="{ item }">
            <span data-test="collection_count_value">{{ item.collectionCount }}</span>
          </template>
          <template #[`item.creator`]="{ item }">
            <Creator :member="item.creator" />
          </template>
          <template #[`item.members`]="{ item }">
            <Members :members="item.members" />
          </template>
          <template #[`item.action`]="{ item }">
            <v-icon
              color="darkGrey"
              medium
              v-on="$listeners"
              @click.stop="$emit('click-action-menu', {
                item,
                event: $event,
              })">
              mdi-dots-vertical
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </template>

    <!-- loading skeleton -->
    <ScheduleSkeleton
      v-else-if="isFetchingLibraries"
      header
      :header-columns="5"
      :body-rows="6" />

    <!-- empty page -->
    <AppEmptyList
      v-else-if="!isFetchingLibraries && !getSortedLibraries.length"
      from="libraries" />
  </div>
</template>
<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import TableHeadersToMobile from '@/mixins/TableHeadersToMobile';
// components
import Creator from '@/components/App/AppTable/AppTableMember';
import Members from '@/components/App/AppTable/AppTableMembers';
import ScheduleSkeleton from '@/components/App/AppSkeleton/ScheduleSkeleton';
export default {
  name: 'LibrariesTable',
  components: {
    Creator,
    Members,
    ScheduleSkeleton,
    AppEmptyList: () => import('@/components/App/AppEmptyList'),
  },
  mixins: [TableHeadersToMobile],
  data: function () {
    return {
      // for TableHeadersToMobile mixin
      MOBILE_COLS_TO_RENDERING: [
        'name',
        'action',
      ],
      headers: [
        {
          text: 'Library name', value: 'name', class: 'fs-16',
          sortable: false,
          cellClass: 'library__name--cell',
        },
        {
          text: 'Collection Count', value: 'collectionCount', align: 'center', class: 'fs-16',
          sortable: false,
        },
        {
          text: 'Creator', value: 'creator', class: 'fs-16',
          sortable: false,
        },
        {
          text: 'Members', align: 'right', sortable: false, value: 'members', class: 'fs-16',
        },
        {
          text: '', value: 'action', sortable: false, align: 'right', class: 'fs-16',
        },
      ],
      permissionTypes: [
        {
          value: 'viewer', text: 'Viewer',
        },
        {
          value: 'editor', text: 'Editor',
        },
        {
          value: 'creator', text: 'Creator',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('Libraries', ['getSortedLibraries']),
    ...mapState('Libraries', ['isFetchingLibraries']),
  },
  methods: {
    navigateToCollectionList({ id }) {
      this.$router.push({
        path: id === 'community' ? `community-collections/${id}` : `collections/${id}`,
      });
    },
  },
};
</script>
