<template>
  <div>
    <v-row>
      <v-col
        v-for="i in headerColumns"
        :key="i">
        <v-skeleton-loader
          type="heading" />
      </v-col>
    </v-row>
    <v-row
      v-for="i in bodyRows"
      :key="i">
      <v-col cols="12">
        <v-skeleton-loader
          type="divider" />
      </v-col>
      <v-col
        v-for="j in headerColumns"
        :key="j">
        <v-skeleton-loader
          type="text" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'ScheduleSkeleton',
  props: {
    bodyRows: {
      type: Number,
      default: 6,
    },
    headerColumns: {
      type: Number,
      default: 6,
    },
  },
  computed: {
    getTableSkeletonBodyType() {
      return `table-row-divider@${this.bodyRows}`;
    },
  },
};
</script>
<style scoped>
</style>
